import getElementsArray from 'get-elements-array';

export function freezeProps(element, props = []) {
	if (props && props.length > 0) {
		const rect = element.getBoundingClientRect();
		props.forEach(prop => {
			element.style[prop] = rect[prop.replace('min-', '')] + 'px';
		});
	}
}
export function freezeSize(element) {
	freezeProps(element, ['min-width', 'min-height']);
}

export function unfreezeSize(element) {
	element.style['min-width'] = '';
	element.style['min-height'] = '';
}

export function getPosition(elements) {
	return getElementsArray(elements).map(element => ({
		element,
		top: element.offsetTop,
		left: element.offsetLeft,
		width: element.offsetWidth,
		height: element.offsetHeight
	}));
}
export function freezePosition(elements) {
	getPosition(elements).map(item => {
		item.element.style.top = item.top + 'px';
		item.element.style.left = item.left + 'px';
		item.element.style.width = item.width + 'px';
		item.element.style.height = item.height + 'px';
		item.element.style.position = 'absolute';
		return item;
	});
}

export function unfreeze(elements) {
	getElementsArray(elements).forEach(element => {
		element.style.top = '';
		element.style.left = '';
		element.style.width = '';
		element.style.height = '';
		element.style.position = '';
	});
}

export function getBoundingRect(elements) {
	return getElementsArray(elements).map(element => {
		const rect = element.getBoundingClientRect();
		return {
			element,
			top: rect.top,
			left: rect.left,
			width: rect.width,
			height: rect.height
		};
	});
}
export function freezeBoundingRect(elements) {
	getBoundingRect(elements).map(item => {
		item.element.style.top = item.top + 'px';
		item.element.style.left = item.left + 'px';
		item.element.style.width = item.width + 'px';
		item.element.style.height = item.height + 'px';
		item.element.style.position = 'fixed';
		return item;
	});
}
