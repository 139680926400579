export function frame() {
	return new Promise(requestAnimationFrame);
}

export function transitionend(element, property) {
	return new Promise(resolve => {
		element.addEventListener('transitionend', function listener(event) {
			if (event.target === element && event.propertyName === property) {
				element.removeEventListener('transitionend', listener);
				resolve(element);
			}
		});
	});
}

function parseParameters(parameters, property) {
	if (typeof parameters === 'string') {
		return parameters;
	}
	if (typeof parameters[property] === 'undefined') {
		throw new TypeError('Each property must have matching parameters');
	}

	return parameters[property];
}

export async function fromTo(element, from, to, extraParameters = '0.5s') {
	if (from) {
		Object.keys(from).forEach(property => {
			element.style.setProperty(property, from[property]);
		});
		await frame();
		if (!to) {
			to = {};
			Object.keys(from).forEach(property => {
				to[property] = '';
			});
		}
	}

	await frame();
	const animations = Object.keys(to).map(property => {
		if (to[property] === from[property]) {
			return Promise.resolve();
		}
		element.style.transition = `${property} ${parseParameters(extraParameters, property)}`;
		element.style.setProperty(property, to[property]);
		return transitionend(element, property);
	});
	return Promise.all(animations);
}
export function from(element, from, extraParameters) {
	return fromTo(element, from, false, extraParameters);
}
export async function to(element, to, extraParameters) {
	return fromTo(element, false, to, extraParameters);
}
