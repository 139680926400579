import select from 'select-dom';
import serialize from 'form-serialize';

function setupForm(form) {
	var timeout;
	var submitButton = form.querySelector('[type="submit"]');
	var submitText = submitButton.textContent;
	form.addEventListener('submit', function (e) {
		clearTimeout(timeout);
		submitButton.textContent = 'Invio in corso';
		submitButton.setAttribute('disabled', true);
		var success = function () {
			submitButton.textContent = 'Inviato!';
			form.reset();
			always();
		};
		var error = function () {
			submitButton.textContent = 'Non inviato. Riprovare?';
			always();
		};
		var always = function () {
			timeout = setTimeout(function () {
				submitButton.removeAttribute('disabled');
				submitButton.textContent = submitText;
			}, 3000);
		};

		var request = new XMLHttpRequest();
		request.open(form.method.toUpperCase(), form.action, true);
		request.onerror = error;
		request.onload = function () {
			if (request.status >= 200 && request.status < 400) {
				success();
			} else {
				error();
			}
		};

		request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
		request.setRequestHeader('Accept', 'application/json');
		request.send(serialize(form));

		e.preventDefault();
	}, false);
}

function init() {
	select.all('.js-ajax-form').forEach(setupForm);
}

init();

