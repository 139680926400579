import on from 'on-off';
import loadImage from 'image-promise';
import * as ap from '../modules/absolutely-position';
import * as cssTransitions from '../modules/css-transitions';


function getSelfIndex(element) {
	return [].indexOf.call(element.parentElement.children, element);
}

function prepareContent(content) {
	if (content.dataset.src) {
		content.hidden = true;
		content.src = content.dataset.src;
		delete content.dataset.src;
	} else {
		content.visibility = 'hidden';
	}

	if (content.tagName === 'IMG') {
		return loadImage(content);
	}

	return content;
}

on('.Section-cover-nav', 'mouseenter', async ({currentTarget: section}) => {
	loadImage(section.querySelectorAll('img'));
});

let isAnimating = false;
on('.Section-cover-th-link', 'click', async ({currentTarget: button}) => {
	if (isAnimating) {
		return;
	}
	isAnimating = true;

	const largeImages = button.parentElement.previousElementSibling.children;

	const next = {
		button,
		index: getSelfIndex(button),
		thumbnail: button.firstElementChild,
	};
	next.large = largeImages[next.index];

	const currentButton = button.parentElement.querySelector('[disabled]');
	const current = {
		button: currentButton,
		index: getSelfIndex(currentButton),
		thumbnail: currentButton.firstElementChild,
	};
	current.large = largeImages[current.index];

	next.thumbnail.style.opacity = 0.7;
	await Promise.all([
		prepareContent(next.large.firstElementChild),
		prepareContent(next.thumbnail)
	]);
	next.thumbnail.style.opacity = '';

	const parent = current.large.parentElement;
	const parentFrom = parent.getBoundingClientRect();
	ap.freezePosition(current.large);
	next.large.hidden = false;
	next.large.firstElementChild.hidden = false;
	const parentTo = parent.getBoundingClientRect();

	cssTransitions.fromTo(parent, {
		height: parentFrom.height + 'px'
	}, {
		height: parentTo.height + 'px'
	}, '0.3s').then(() => {
		parent.style.removeProperty('height');
	});

	current.large.classList.add('js-hidden');
	cssTransitions.transitionend(current.large, 'transform').then(() => {
		ap.unfreeze(current.large);
		current.large.classList.remove('js-hidden');
		current.large.hidden = true;
		isAnimating = false;
	});

	current.button.disabled = false;
	next.button.disabled = true;
});

