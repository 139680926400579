import select from 'select-dom';
import on from 'on-off';

const currentClass = 'SiteNav-link--current';
let current = document.querySelector('.' + currentClass);

function openMobileMenu(event) {
	select('.Head').classList.toggle('is-open');
	event.preventDefault();
}

function closeMobileMenu(event) {
	if (
		!event.defaultPrevented &&
		!event.target.closest('a, button') &&
		select.exists('.is-open')
	) {
		select('.is-open').classList.remove('is-open');
	}
}
function setCurrentToThis({target}) {
	current.classList.remove(currentClass);
	current = target;
	current.classList.add(currentClass);
}
function init() {
	on('.Head, .Head-overlay', 'click', closeMobileMenu);
	on('.SiteNav-toggle', 'click', openMobileMenu);
	on('.SiteNav-link', 'click', setCurrentToThis);
}

init();
